// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-pauliescanlon-gatsby-theme-terminal-src-pages-404-js": () => import("./../../node_modules/@pauliescanlon/gatsby-theme-terminal/src/pages/404.js" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-terminal-src-pages-404-js" */),
  "component---node-modules-pauliescanlon-gatsby-theme-terminal-src-pages-dummy-page-mdx": () => import("./../../node_modules/@pauliescanlon/gatsby-theme-terminal/src/pages/dummy-page.mdx" /* webpackChunkName: "component---node-modules-pauliescanlon-gatsby-theme-terminal-src-pages-dummy-page-mdx" */),
  "component---src-pages-about-mdx": () => import("./../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-posts-mdx": () => import("./../../src/pages/posts.mdx" /* webpackChunkName: "component---src-pages-posts-mdx" */)
}

